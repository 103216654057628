import React, {Component} from "react"

export default class IntakeFormHubspot extends Component {
	componentDidMount(){

		const script = document.createElement('script');
	    script.src = 'https://js.hsforms.net/forms/v2.js';
	    document.body.appendChild(script);
	    
	    script.addEventListener('load', () => {
	    	if (window.hbspt){
				window.hbspt.forms.create({ 
					region: "na1",
					portalId: '5822735',
					formId: "3ee9b47c-83e9-4b90-94fa-92216c0e6c2c",
					target: '#hubspot-form',
			   	})

			   	this.hideBackupButton();
			}
		});		
  	}

  	hideBackupButton = () => {
  		if(document){
  			document.getElementById('backup-button-container').classList.add('hidden'); // hide the backupButton, used as a failsafe
  		}
  	}

  	render(){
	  	return(
	  		<div className="grid-x grid-margin-x">
	  		  <div id="hubspot-form" className="small-12 cell">
	  		  	<div id="backup-button-container">
		  		  	<a href="https://share.hsforms.com/1Pum0fIPpS5CU-pIhbA5sLA3gsun">
		  		  		<button id="backup-button" className="button" >Go to intake form</button>
		  		  	</a>
        			<img src="/assets/images/loading-emblem.gif" alt="Loading Gif" style={{maxWidth: "4rem"}} />
		  		</div>
	  		  </div>
	  		</div>
	  	)
	}
}

