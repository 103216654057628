import React, {Component} from "react"
import {Link} from "gatsby"
import HeaderFooter from '../components/headerFooter'
import UnpackUnitChildren from '../functions/unpackUnitChildren'
import {UnitData} from "../data/unitData"
import IntakeFormHubspot from "../components/intakeFormHubspot"

export default class Intake extends React.Component {
	constructor(props) {
		super(props); 
	}

	render(){
		return(
			<>
          		<HeaderFooter>
          		<div className="grid-container">
          			<div className="grid-y grid-margin-x">
	          			<div className="cell" style={{marginTop: "1.25rem", marginBottom:"2.5rem", backgroundColor: "#eaeaea", padding: "1.25rem"}}>
	          				<div className="grid-x grid-margin-x" style={{backgroundColor: "#eaeaea", alignItems: "center", justifyContent:"center"}}>
	          					<div  className="small-12 large-8 cell">
									<p className="h5">Need Team, Squad, rec sports league, or memorial gear?</p>
									<p style={{marginBottom: 0}}>We can create special gear for Units of any size... but for non-platoons/non-Batteries, please click the button and use our automated intake form.</p>
			          			</div>
			          			<div className="small-12 large-4 cell">
			          			<a href="https://www.emblemathletic.com/style-quiz?utm_source=wainwright+gear+store">
			          				<button style={{marginBottom: 0}} className="button">Get custom gear for your Team/Squad/Rec Sports team, etc.</button>
			          			</a>
			          			</div>
			          		</div>
						</div>
	          			<div className="small-12 cell">
		          			<h2 className="h3"><strong>Get custom gear live on this site </strong> <span className= "subhead"> (for Platoons/Troops, Companies/BTRYs/Squadrons, SFRGs, &amp; MWRs)</span></h2>
		          			<p>
		          				For Platoon Leaders, Company Commanders, SFRG leaders, etc. start here to get a fully-stocked, custom store just for your unit.
		          				<br/><strong>If you need hands-free, no effort fundraising for your SFRG, MWR, or other morale-focused fund, just ask.</strong> We're set up to connect seamlessly to your offical bank account and send $3 from every item sold on your store with no additional effort for you.
		          			</p>
		          			<p className="h4">To get set up faster than an E4 dodging a detail with no-obligation at no-cost, just:</p>
		          			<ol> 
		          				<li>Fill out the form to get set up with a time for your free design consultation (if you prefer email, we'll send some questions over)</li>
		          				<li>You'll get Unit gear and casual/civilian wear gear designs created for your Unit</li>
		          				<li>Make any design tweaks or revisions you want. You (or your Commander) gets the final say</li>
		          				<li>(For BRTY PT approved gear) Get the final PT approval from your Battalion Commander for your Unit shirt</li>
		          			</ol>
							<IntakeFormHubspot />
						</div>
					</div>
				</div>
				</HeaderFooter>
			</>
		)
	}
}